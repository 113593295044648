import React from "react";
import Box from "../Box";

export default function Sticky({
  top = 0,
  bottom = "auto",
  sx,
  ...otherProps
}) {
  return (
    <Box sx={{ position: "sticky", top, bottom, ...sx }} {...otherProps} />
  );
}
