import React from "react";
import { useResponsiveValue } from "@theme-ui/match-media";

import GridRow from "../../commons/GridRow";
import Button from "../../commons/Button";
import Box from "../../commons/Box";
import Sticky from "../../commons/Sticky";
import AnimateParallax from "../../commons/AnimateParallax";
import RichTextRenderer from "../../commons/RichTextRenderer";
import { linkResolver } from "../../../utils/links";
import Card from "./Card";
import { carouselStyles } from "../../../utils/styles";

const offset = 500;

export default function ParallaxCardBlock({
  _rawTitle,
  subtitle,
  cards,
  buttons,
  ...otherProps
}) {
  const ref = React.useRef();
  const isMobile = useResponsiveValue([true, false]);

  return (
    <Box ref={ref} as="section">
      <GridRow
        className="bg-circle"
        sx={{
          backgroundColor: "background",
          gridAutoRows: "min-content",
          py: [5, "gridGap"],
          rowGap: ["gridGap", 0],
        }}
      >
        <GridRow.Col
          gridColumn={["start / end", "start / span 6", "start / span 4"]}
          sx={{
            textAlign: ["center", "start"],
          }}
        >
          <Sticky top="gridGap">
            <RichTextRenderer blocks={_rawTitle} />
            {!!buttons.length && (
              <Box sx={{ mt: 4 }}>
                {buttons.map(linkResolver).map((link) => (
                  <Button
                    key={link.to || link.href}
                    variant="gradient"
                    {...link}
                  />
                ))}
              </Box>
            )}
          </Sticky>
        </GridRow.Col>
        <GridRow.Col
          gridColumn={["1 / -1", "start / end", "6 / end"]}
          sx={{
            position: "relative",
            justifySelf: "flex-end",
            width: "100%",
            mb: [0, cards.length % 2 ? 7 : offset - 150],
          }}
        >
          <GridRow
            sx={{
              display: ["flex", "grid"],
              justifyContent: ["inherit", "end"],
              gridTemplateColumns: "repeat(2, minmax(240px, 275px))",
              gridAutoColumns: "93%",
              gridAutoFlow: "row",
              columnGap: [0, "gridGap"],
              rowGap: [0, 3],
              overflow: ["auto", "visible"],
              mt: [4, 2, -4],
              mb: [0, 4],
              "&:after": {
                content: "''",
                flex: "0 0 1px",
              },
              ...carouselStyles,
            }}
          >
            {cards.map((card, i) => {
              return (
                <GridRow.Col
                  key={card._key}
                  sx={{
                    gridColumn: "auto",
                    position: ["static", "relative"],
                    scrollSnapAlign: "center",
                    maxWidth: [325, "100%"],
                    width: ["88%", "auto"],
                    ml: ["gridGap", 0],
                    flexShrink: 0,
                    transform: ["none !important", "none"],
                    "&:last-child": {
                      mr: ["gridGap", 0],
                    },
                  }}
                  style={{
                    zIndex: cards.length - 1 - i,
                    top: i % 2 ? offset : 0,
                  }}
                >
                  {isMobile ? (
                    <Card
                      title={card.title}
                      image={card.image}
                      description={card._rawDescription}
                      button={card.button[0]}
                    />
                  ) : (
                    <AnimateParallax
                      index={i}
                      refEl={ref}
                      style={{ height: "100%" }}
                    >
                      <Card
                        title={card.title}
                        image={card.image}
                        description={card._rawDescription}
                        button={card.button[0]}
                      />
                    </AnimateParallax>
                  )}
                </GridRow.Col>
              );
            })}
          </GridRow>
        </GridRow.Col>
      </GridRow>
    </Box>
  );
}
