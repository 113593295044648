import React from "react";

import Box from "../../commons/Box";
import Heading from "../../commons/Heading";
import Image from "../../commons/Image";
import Button from "../../commons/Button";
import RichTextRenderer from "../../commons/RichTextRenderer";
import { linkResolver } from "../../../utils/links";

export default function Card({
  title,
  image,
  description,
  button,
  ...otherProps
}) {
  return (
    <Box
      as="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "card",
        boxShadow: "card",
        p: 3,
        color: "white",
        height: "100%",
        maxWidth: 325,
        background: (theme) =>
          `linear-gradient(to top, ${theme.colors.blue} 0%, ${theme.colors.red} 100%)`,
      }}
    >
      {image && image.asset && (
        <Box sx={{ p: 3 }}>
          <Image
            fluid={{
              ...image.asset.fluid,
              // override aspect ratio so image is always squared
              aspectRatio: 1,
            }}
            imgStyle={{ objectFit: "contain" }}
            alt={image.asset.alt}
            sx={{
              flexShrink: 0,
              borderRadius: image.asset.metadata.isOpaque ? "circle" : 0,
              mb: 3,
            }}
          />
        </Box>
      )}
      <Heading as="h3" variant="h4" sx={{ color: "white", mt: "auto" }}>
        {title}
      </Heading>
      {description && (
        <Box sx={{ mt: 1 }}>
          <RichTextRenderer blocks={description} />
        </Box>
      )}
      {button && (
        <Button
          {...linkResolver(button)}
          sx={{
            borderRadius: "inherit",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            mx: -3,
            mt: 3,
            mb: -3,
            py: ["inherit", 3],
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            color: "white",
            height: "auto",
          }}
        />
      )}
    </Box>
  );
}
