import React from "react";
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
} from "framer-motion";

const AnimateParallax = ({
  index,
  strength = 200,
  spring = { stiffness: 200, damping: 100 },
  refEl,
  style,
  ...otherProps
}) => {
  const elInfos = React.useRef();
  const { scrollY } = useViewportScroll();

  const scrollYProgress = useTransform(scrollY, (value) => {
    if (!elInfos.current) return 0;
    return Math.min(
      Math.max(
        0,
        (value + window.innerHeight - elInfos.current.top) /
          (elInfos.current.bb.height + window.innerHeight)
      ),
      1
    );
  });

  React.useEffect(() => {
    elInfos.current = {
      bb: refEl.current.getBoundingClientRect(),
      top: refEl.current.offsetTop,
    };
  }, [refEl]);

  const y = useTransform(scrollYProgress, (value) => {
    return index % 2 ? -value * strength : value * strength;
  });
  const animY = useSpring(y, spring);

  return <motion.div style={{ y: animY, ...style }} {...otherProps} />;
};

export default AnimateParallax;
